import Chart from 'chart.js';

var myChart = ""
export function barChart( transfer_label_list_data ,transfer_data_list1_data ,transfer_data_list2_data ) {
  // // ------------------------------------------------------
  // // @Bar Charts
  // // ------------------------------------------------------

  const barChartBox = document.getElementById('bar-chart');
  if(myChart.constructor === Chart){
    myChart.destroy();
  };

  // ラベルとデータ
  let transfer_label_list = transfer_label_list_data
  let transfer_data_list1 = transfer_data_list1_data
  let transfer_data_list2 = transfer_data_list2_data

  // データのプロパティ
  let transfer_set1 = {
    label: '行き',
    data: transfer_data_list1,            // プロットするデータ
    borderWidth: 2,             // 折れ線の太さ
    backgroundColor: "#b8dcff",     // 折れ線の色
    fill: false,
  };

  // データのプロパティ
  let transfer_set2 = {
    label: '帰り',
    data: transfer_data_list2,            // プロットするデータ
    borderWidth: 2,             // 折れ線の太さ
    backgroundColor: "#7fbfff",     // 折れ線の色
    fill: false,
  };


  if (barChartBox) {
    const barCtx = barChartBox.getContext('2d');
    barChartBox.height = 60;

    myChart = new Chart(barCtx, {
      type: 'bar',
      data: {
        labels: transfer_label_list,
        datasets: [transfer_set1, transfer_set2],
      },

      options: {
        responsive: true,
        legend: {
          position: 'bottom',
        },
        scales: {
          yAxes: [                           // Ｙ軸設定
              {
                  ticks: {                       // 目盛り
                      min: 0,                        // 最小値
                      stepSize: 2,                   // 軸間隔
                      fontSize: 14                   // フォントサイズ
                  }
              }
          ]
        },
      },
    });
  }
}
