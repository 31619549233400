import './chartJS';
import './easyPieChart';
import './sparkline';
import { lineChart } from './lineChart';
import { barChart } from './barChart';

const lineUrl = "./assets/ajax/line_chart_data.php"
const barUrl = "./assets/ajax/bar_chart_data.php"

$(window).on("load", function () {
  if ( $("#mainContent").find(".js-line-date").length ) {
    lineChartAjax(lineUrl ,thisMonth () )
  }
  if ( $("#mainContent").find(".js-bar-date").length ) {
    barChartAjax(barUrl ,thisMonth () )
  }

  $("#mainContent").on('click', '.js-line-date', function(e){
    e.preventDefault()
    let lineDate = $(this).attr('data-line-date')

    let timestamp = new Date(lineDate);
    //前月へ
    let linePrevTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth() - 1), 1, 0, 0, 0);
    let linePrev = linePrevTimestamp.getFullYear() + "-" + ( linePrevTimestamp.getMonth() + 1) + "-01"
    let linePrevDisp = linePrevTimestamp.getFullYear() + "月" + ( linePrevTimestamp.getMonth() + 1) + "月"
    //翌月へ
    let lineNextTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth() + 1), 1, 0, 0, 0);
    let lineNext = lineNextTimestamp.getFullYear() + "-" + ( lineNextTimestamp.getMonth() + 1) + "-01"
    let lineNextDips = lineNextTimestamp.getFullYear() + "月" + ( lineNextTimestamp.getMonth() + 1) + "月"
    //現在
    let currentTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth()), 1, 0, 0, 0);
    let current = currentTimestamp.getFullYear() + "-" + ( currentTimestamp.getMonth() + 1) + "-01"
    let currentDisp = currentTimestamp.getFullYear() + "月" + ( currentTimestamp.getMonth() + 1) + "月"

    let html = `
    <li class="page-item"><a class="page-link js-line-date" href="#" data-line-date="${linePrev}">${linePrevDisp}</a></li>
    <li class="page-item disabled"><a class="page-link" href="#" data-line-date="${current}">${currentDisp}</a></li>
    <li class="page-item"><a class="page-link js-line-date" href="#" data-line-date="${lineNext}">${lineNextDips}</a></li>
    `
    $("#line-page-navi").empty()
    $("#line-page-navi").append(html)

    lineChartAjax(lineUrl ,lineDate)
  });
  $("#mainContent").on('click', '.js-bar-date', function(e){
    e.preventDefault()
    let barDate = $(this).attr('data-bar-date')

    let timestamp = new Date(barDate);
    //前月へ
    let barPrevTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth() - 1), 1, 0, 0, 0);
    let barPrev = barPrevTimestamp.getFullYear() + "-" + ( barPrevTimestamp.getMonth() + 1) + "-01"
    let barPrevDisp = barPrevTimestamp.getFullYear() + "月" + ( barPrevTimestamp.getMonth() + 1) + "月"
    //翌月へ
    let barNextTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth() + 1), 1, 0, 0, 0);
    let barNext = barNextTimestamp.getFullYear() + "-" + ( barNextTimestamp.getMonth() + 1) + "-01"
    let barNextDips = barNextTimestamp.getFullYear() + "月" + ( barNextTimestamp.getMonth() + 1) + "月"
    //現在
    let currentTimestamp = new Date(timestamp.getFullYear(), (timestamp.getMonth()), 1, 0, 0, 0);
    let current = currentTimestamp.getFullYear() + "-" + ( currentTimestamp.getMonth() + 1) + "-01"
    let currentDisp = currentTimestamp.getFullYear() + "月" + ( currentTimestamp.getMonth() + 1) + "月"

    let html = `
    <li class="page-item"><a class="page-link js-bar-date" href="#" data-bar-date="${barPrev}">${barPrevDisp}</a></li>
    <li class="page-item disabled"><a class="page-link" href="#" data-bar-date="${current}">${currentDisp}</a></li>
    <li class="page-item"><a class="page-link js-bar-date" href="#" data-bar-date="${barNext}">${barNextDips}</a></li>
    `
    $("#bar-page-navi").empty()
    $("#bar-page-navi").append(html)

    barChartAjax(barUrl ,barDate)

  });

});



// lineChart( attendance_label_list_data ,attendance_data_list1_data ,attendance_data_list2_data ,attendance_data_list3_data ,attendance_data_list4_data )
// barChart( transfer_label_list_data ,transfer_data_list1_data ,transfer_data_list2_data )

function lineChartAjax (lineUrl ,lineDate ) {
  $.ajax({
    type: 'GET', // リクエストのタイプ
    url: lineUrl, // リクエストを送信するURL
    data:  { 'date': lineDate }, // サーバーに送信するデータ
    dataType: 'json' // サーバーから返却される型
  })
  // ajaxからリクエストを受けとり
  .done(function (data) {
    let jsonString = JSON.stringify(data);
    let jsonObject = JSON.parse(jsonString);
    let attendance_label_data = [];
    let attendance_list1_data = [];
    let attendance_list2_data = [];
    let attendance_list3_data = [];
    let attendance_list4_data = [];

    $.each(jsonObject, function(index, value) {
      attendance_label_data.push(value.day);
      attendance_list1_data.push(value.data_list1);
      attendance_list2_data.push(value.data_list2);
      attendance_list3_data.push(value.data_list3);
      attendance_list4_data.push(value.data_list4);
    })
    lineChart( attendance_label_data ,attendance_list1_data ,attendance_list2_data ,attendance_list3_data ,attendance_list4_data )
  })
}

function barChartAjax (barUrl ,barDate ) {
  $.ajax({
    type: 'GET', // リクエストのタイプ
    url: barUrl, // リクエストを送信するURL
    data:  { 'date': barDate }, // サーバーに送信するデータ
    dataType: 'json' // サーバーから返却される型
  })
  // ajaxからリクエストを受けとり
  .done(function (data) {
    let jsonString = JSON.stringify(data);
    let jsonObject = JSON.parse(jsonString);
    let transfer_label_data = [];
    let transfer_list1_data = [];
    let transfer_list2_data = [];

    $.each(jsonObject, function(index, value) {
      transfer_label_data.push(value.day);
      transfer_list1_data.push(value.data_list1);
      transfer_list2_data.push(value.data_list2);
    })
    barChart( transfer_label_data ,transfer_list1_data ,transfer_list2_data )
  })
}

function thisMonth () {
  var today = new Date();
  var FD = today.getFullYear() + "-" + ( today.getMonth() + 1) + "-01";
  return FD
}