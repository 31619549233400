import * as $ from 'jquery';
import 'datatables';

export default (function () {

  const ele = document.getElementById('workTable')
  if ( ele ) {

    // $.extend( $.fn.dataTable.defaults, {
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Japanese.json"
    //   }
    // });

    // //URLのパラメータを取得
    // let urlParam = window.location.search;
    // //オブジェクトに変換
    // let params = new URLSearchParams(urlParam);
    // //パラメータを取得
    // let user_id = params.get('user_id');
    // let work_date = ""
    // console.log(params.get('date'))
    // if ( params.get('date') ) {
    //   work_date = params.get('date');
    // }

    // let dispEle = $('#workTable').attr('data-target');
    // let dispTarget =  []
    // if ( dispEle !== "" ) {
    //   dispTarget = $('#workTable').attr('data-target').split(",") ;
    // }

    // const workListUrl = "https://amyusement.fun/test/Edge_System/assets/ajax/work_list.php"
    // let tableData = $('#workTable').DataTable({
    //   ajax: {
    //     url: workListUrl,
    //     dataSrc: 'work',
    //     type:"POST",
    //     // data:  {
    //     //   "user_id":user_id,
    //     //   "work_date":work_date,
    //     // },
    //     data: function (d) {
    //       d.search_from = $("#search_from").val();
    //       d.search_to = $("#search_to").val();
    //       d.user_id = user_id;
    //       d.work_date = work_date;
    //       // d.custom = $('#myInput').val();
    //       // etc
    //     }
    //   },
    //   processing: true,
    //   serverSide: true,
    //   deferRender: true,
    //   scrollX: true,
    //   scrollY:false,
    //   scrollCollapse : true,
    //   order: [],
    //   orderCellsTop: true,
    //   //stateSave: true,
    //   paging: true,
    //   pagingType: "full_numbers",
    //   lengthMenu: [ 1, 2, 3, 4, 5, 10, 15, 20, 50, 100 ],
    //   displayLength: 100,
    //   sDom: "<'table_header_area row'<'header_left col-sm-6'l><'header_right row col-sm-6'<'col-sm-5'<'#table_search_from'>><'col-sm-2 text-center'<'#table_search_term'>><'col-sm-5'<'#table_search_to'>>>>t<'table_footer_area'ip>",
    //   createdRow: function (row, data, dataIndex) {
    //     $(row).addClass('js-attendance-box');
    //   },
    //   columns: [
    //     {
    //       width: "50px",
    //       orderable: false ,
    //       data: "work_data",
    //       render: function(data, type, row, meta) {

    //         let hidden_input = '';
    //         if ( dispTarget.includes("1") ) {
    //           hidden_input += '<input type="hidden" name="work_day" class="form-control js-work_day" value="' + data.work_day  +'">';
    //         }
    //         if ( dispTarget.includes("3") ) {
    //           hidden_input += '<input type="hidden" name="work_record" class="form-control js-work_record" value="' + data.work_record  +'">';
    //         }
    //         if ( dispTarget.includes("4") ) {
    //           hidden_input += '<input type="hidden" name="work_attendance" class="form-control js-work_attendance" value="' + data.work_attendance.target  +'">';
    //         }
    //         if ( dispTarget.includes("5") ) {
    //           hidden_input += '<input type="hidden" name="work_location" class="form-control js-work_location" value="' + data.work_location.target  +'">';
    //         }
    //         if ( dispTarget.includes("7") ) {
    //           hidden_input += '<input type="hidden" name="work_detail" class="form-control js-work_detail" value="' + data.work_detail  +'">';
    //         }
    //         if ( dispTarget.includes("8") ) {
    //           hidden_input += '<input type="hidden" name="work_quantity" class="form-control js-work_quantity" value="' + data.work_quantity  +'">';
    //         }
    //         if ( dispTarget.includes("11") ) {
    //           hidden_input += '<input type="hidden" name="work_start" class="form-control js-work_start" value="' + data.work_start  +'">';
    //         }
    //         if ( dispTarget.includes("12") ) {
    //           hidden_input += '<input type="hidden" name="work_end" class="form-control js-work_end" value="' + data.work_end  +'">';
    //         }
    //         if ( dispTarget.includes("13") ) {
    //           hidden_input += '<input type="hidden" name="work_going" class="form-control js-going" value="' + data.work_transfer.going.check  +'">';
    //           hidden_input += '<input type="hidden" name="work_going_time" class="form-control js-work_going_time" value="' + data.work_transfer.going.time  +'">';
    //           hidden_input += '<input type="hidden" name="work_return" class="form-control js-return" value="' + data.work_transfer.return.check  +'">';
    //           hidden_input += '<input type="hidden" name="work_return_time" class="form-control js-work_return_time" value="' + data.work_transfer.return.time  +'">';
    //         }
    //         if ( dispTarget.includes("14") ) {
    //           hidden_input += '<input type="hidden" name="work_charge" class="form-control js-work_charge" value="' + data.work_charge.target  +'">';
    //         }
    //         let html = '<div class="text-center"><a class="js-btn btn btn-info js-edit-btn1" data-id="' + data.work_id + '" data-day="' + data.work_day + '" href="#"><i class="c-teal-500 ti-pencil"></i></a>' + hidden_input + '</div>';
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_day",
    //       render: function(data, type, row, meta) {
    //         console.log(data)
    //         let html = "";
    //         html = `
    //         <div class="cs-form">
    //           <input name="work_day" type="date" class="form-control js-work_day" value="${data}" />
    //         </div>
    //         `
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "100px",
    //       data: "work_attendance",
    //       render: function(data, type, row, meta) {
    //         let itemList = data.list
    //         let html = "";
    //         html += '<div class="align-items-center col-md-12">';
    //         html += '<select name="work_attendance" class="form-select js-work_attendance" >';
    //         html += '<option value="">選択してください</option>'
    //         Object.keys(itemList).forEach(function (key) {
    //           if ( key == data.target ) {
    //             html += '<option value="' + key + '" selected>' + itemList[key] + '</option>'
    //           } else {
    //             html += '<option value="' + key + '">' + itemList[key] + '</option>'
    //           }
    //         })
    //         html += '</select>'
    //         html += '</div>'
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "100px",
    //       data: "work_condition",
    //       render: function(data, type, row, meta) {
    //         let itemList = data.list
    //         let html = "";
    //         html += '<div class="align-items-center col-md-12">';
    //         html += '<select name="work_condition" class="form-select js-work_condition" >';
    //         html += '<option value="">選択してください</option>'
    //         Object.keys(itemList).forEach(function (key) {
    //           if ( key == data.target ) {
    //             html += '<option value="' + key + '" selected>' + itemList[key] + '</option>'
    //           } else {
    //             html += '<option value="' + key + '">' + itemList[key] + '</option>'
    //           }
    //         })
    //         html += '</select>'
    //         html += '</div>'
    //         return html;
    //       },
    //     } ,
    //     { width: "150px",
    //       data: "work_location",
    //       render: function(data, type, row, meta) {
    //         let itemList = data.list
    //         let html = "";
    //         html += '<div class="align-items-center col-md-12">';
    //         html += '<select name="work_location" class="form-select js-work_location">';
    //         html += '<option value="">選択してください</option>'
    //         Object.keys(itemList).forEach(function (key) {
    //           if ( key == data.target ) {
    //             html += '<option value="' + key + '" selected>' + itemList[key] + '</option>'
    //           } else {
    //             html += '<option value="' + key + '">' + itemList[key] + '</option>'
    //           }
    //         })
    //         html += '</select>'
    //         html += '</div>'
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_genre",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = `
    //         <div class="col-sm-12">
    //           <input name="work_genre" type="text" class="form-control js-work_genre" value="${data.work_genre}" ${disabled}>
    //         </div>
    //         `;
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_detail",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = `
    //         <div class="col-sm-12">
    //           <input name="work_detail" type="text" class="form-control js-work_detail" value="${data.work_detail}" ${disabled}>
    //         </div>`;
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "100px",
    //       data: "work_quantity",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = `
    //         <div class="col-sm-12">
    //           <input name="work_quantity" type="number" class="form-control js-work_quantity" value="${data.work_quantity}" ${disabled}>
    //         </div>`;
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_lunch",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = "";
    //         html += '<div class="align-items-center col-md-12">';
    //         html += '<select name="work_lunch" class="form-select js-work_lunch"' +  disabled + '>';
    //         html += '<option value="">選択してください</option>'
    //         if ( data == "有" ) {
    //           html += '<option value="有" selected>有</option>'
    //           html += '<option value="無" >無</option>'
    //         } else {
    //           html += '<option value="有" >有</option>'
    //           html += '<option value="無" selected>無</option>'
    //         }
    //         html += "</select>"
    //         html += "</div>"
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_hours",
    //       render: function(data, type, row, meta) {
    //         let html = "";
    //         html = `
    //         <div class="cs-form">
    //           ${data}<input name="work_hours" type="hidden" class="form-control js-work_hours" value="${data}" />
    //         </div>`
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "100px",
    //       data: "work_start",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = "";
    //         html = `
    //         <div class="cs-form">
    //           <input name="work_start" type="time" class="form-control js-work_start" value="${data.work_start_time}" ${disabled}/>
    //         </div>`
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "100px",
    //       data: "work_end",
    //       render: function(data, type, row, meta) {
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }
    //         let html = "";
    //         html = `
    //         <div class="cs-form">
    //           <input name="work_end" type="time" class="form-control js-work_end" value="${data.work_end_time}" ${disabled}/>
    //         </div>`
    //         return html;
    //       },
    //     } ,

    //     {
    //       width: "300px",
    //       data: "work_transfer",
    //       render: function(data, type, row, meta) {
    //         let goingCheck = ""
    //         let returnCheck = ""

    //         if ( data.going.check == "1" ) {
    //           goingCheck = "checked";
    //         }
    //         if ( data.return.check == "1" ) {
    //           returnCheck = "checked";
    //         }
    //         let disabled = "";
    //         if ( data.work_attendance != "1" ) {
    //           disabled = "disabled";
    //         }

    //         let html = "";
    //         html = `
    //         <div class="row align-items-center col-md-12">
    //           <div class="row">
    //             <div class="col-5 fs-6 mB-10">
    //               <label id="work_going" class="form-label form-check-label col-md-6 align-middle pT-5">
    //                 <input name="work_going" class="form-check-input js-going" type="checkbox" id="work_going" value="1" ${goingCheck} ${disabled}> 行き
    //               </label>
    //             </div>
    //             <div class="col-5 fs-6 mB-10">
    //               <div class="cs-form">
    //                 <input name="work_going_time" type="time" class="form-control js-work_going_time" id="work_going_time" value="${data.going.time}" disabled />
    //               </div>
    //             </div>
    //           </div>
    //           <div class="row">
    //             <div class="col-5 fs-6 mB-10">
    //               <label id="work_return" class="form-label form-check-label col-md-6 pT-5">
    //                 <input name="work_return" class="form-check-input js-return" type="checkbox" id="work_return" value="1" ${returnCheck} ${disabled}> 帰り
    //               </label>
    //             </div>
    //             <div class="col-5 fs-6 mB-10">
    //               <div class="cs-form">
    //                 <input name="work_return_time" type="time" class="form-control js-work_return_time" id="work_return_time" value="${data.return.time}"  disabled />
    //               </div>
    //             </div>
    //           </div>
    //         </div>`
    //         return html
    //       },
    //     } ,
    //     {
    //       width: "150px",
    //       data: "work_charge",
    //       render: function(data, type, row, meta) {
    //         let html = "";
    //         html += '<div class="align-items-center col-md-12">';
    //         html += '<select name="work_charge" class="form-select js-work_charge">';
    //         html += '<option value="">選択してください</option>'
    //         $.each(data.list, function(index, val) {
    //           if ( val.employee_id == data.target ) {
    //             html += '<option value="' + val.employee_id + '" selected>' + val.employee_name + '</option>'
    //           } else {
    //             html += '<option value="' + val.employee_id + '">' + val.employee_name + '</option>'
    //           }
    //         })
    //         html += "</select>"
    //         html += "</div>"
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "300px",
    //       data: "work_record",
    //       render: function(data, type, row, meta) {
    //         let html = "";
    //         html = `
    //         <div class="col-sm-12">
    //           <textarea name="work_record" class="form-control js-work_record" rows="5">${data}</textarea>
    //         </div>`
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "50px",
    //       orderable: false ,
    //       data: "work_data",
    //       render: function(data, type, row, meta) {
    //         let html = '<div class="text-center"><a class="js-btn btn btn-info js-edit-btn1" data-id="' + data.work_id + '" data-day="' + data.work_day + '"  href="#"><i class="c-teal-500 ti-pencil"></i></a></div>';
    //         return html;
    //       },
    //     } ,
    //     {
    //       width: "50px",
    //       orderable: false ,
    //       data: "work_data",
    //       render: function(data, type, row, meta) {
    //         let html = '<div class="text-center"><a class="js-work-del btn btn-info" data-id="' + data.work_id + '" href="#"><i class="ti-eraser"></i></a></div>';
    //         return html;
    //       },
    //     } ,
    //   ],
    //   initComplete : function(settings, json) {
    //     //検索窓
    //     $('#table_search_from').append('<input class="custom_filter table-between form-control form-control-sm table-date" value="" data-provide="datepicker" id="search_from" name="search_from" placeholder="検索開始日">');
    //     $('#table_search_term').append('～');
    //     $('#table_search_to').append('<input class="custom_filter table-between form-control form-control-sm table-date" value="" data-provide="datepicker" id="search_to" name="search_to" placeholder="検索終了日">');
    //     $('.table-date').datepicker({
    //       format: 'yyyy/mm/dd',
    //     });
    //     $('#mainContent').on( 'change', '.table-date', function (e) {
    //       tableData.draw();
    //     } );
    //   }
    // });



    // if ( dispTarget.length !== 0 ) {
    //   tableData.columns( dispTarget ).visible( false, false );
    // }
    // // tableData.columns.adjust().draw( false ); // adjust column sizing and redraw






    // // 新規登録 クリック時の動作
    // $("#mainContent").on('click', 'a.js-work-entry', function () {

    //   $('#myModal').modal('show');

    //   $("#delete").addClass("d-none");
    //   $("#edit").addClass("d-none");
    //   $("#entry").removeClass("d-none");
    //   $("#modal-title").html("新規")
    //   $('#displayId').html("新規");

    // })

    // // 新規登録 クリック時の動作
    // $("#mainContent").on('click', 'a.js-edit-btn1', function (e) {
    //   e.preventDefault();
    //   alert("個人記録以外の項目は必須です。\n必要項目を入力してください。")
    // })

    // // 新規登録 クリック時の動作
    // $("#mainContent").on('click', 'a.js-edit-btn2', function (e) {
    //   e.preventDefault();
    //   alert("個人記録以外は100文字以上の入力が必要です。")
    // })

    // // 新規登録、編集ボタン クリック時の動作
    // $("#mainContent").on('click', 'a.js-work-edit', function (e) {
    //   e.preventDefault();
    //   if(!confirm('更新しますか？')){
    //     return false;
    //   } else {

    //     const workEditUrl = "https://amyusement.fun/test/Edge_System/assets/ajax/work_edit.php"

    //     let parents = $(this).parent().parent().parent();
    //     let work_id = $(this).attr("data-id")
    //     let work_day = parents.find('.js-work_day').val()
    //     let work_record = parents.find('.js-work_record').val()
    //     let work_attendance = parents.find('.js-work_attendance').val()
    //     let work_location = parents.find('.js-work_location').val()
    //     let work_condition = parents.find('.js-work_condition').val()
    //     let work_genre = parents.find('.js-work_genre').val()
    //     let work_detail = parents.find('.js-work_detail').val()
    //     let work_quantity = parents.find('.js-work_quantity').val()
    //     let work_lunch = parents.find('.js-work_lunch').val()
    //     let work_hours = parents.find('.js-work_hours').val()
    //     let work_start = parents.find('.js-work_start').val()
    //     let work_end = parents.find('.js-work_end').val()
    //     let work_going = ""
    //     let work_going_flag = parents.find('.js-going').prop("checked")
    //     if ( work_going_flag ) {
    //       work_going = "1";
    //     } else{
    //       work_going = "2";
    //     }
    //     let work_going_time = parents.find('.js-work_going_time').val()
    //     let work_return = ""
    //     let work_return_flag = parents.find('.js-return').prop("checked")

    //     if ( work_return_flag ) {
    //       work_return = "1";
    //     } else{
    //       work_return = "2";
    //     }
    //     let work_return_time = parents.find('.js-work_return_time').val()
    //     let work_charge = parents.find('.js-work_charge').val()



    //     $.ajax({
    //       url: workEditUrl,
    //       type: 'POST',
    //       data: {
    //         'work_id': work_id ,
    //         'work_record': work_record ,
    //         'work_attendance': work_attendance ,
    //         'work_location': work_location ,
    //         'work_condition': work_condition ,
    //         'work_genre': work_genre ,
    //         'work_detail': work_detail ,
    //         'work_quantity': work_quantity ,
    //         'work_day': work_day ,
    //         'work_lunch': work_lunch ,
    //         'work_hours': work_hours ,
    //         'work_start': work_start ,
    //         'work_end': work_end ,
    //         'work_going': work_going ,
    //         'work_going_time': work_going_time ,
    //         'work_return': work_return ,
    //         'work_return_time': work_return_time ,
    //         'work_charge': work_charge ,
    //       }
    //     })
    //     // Ajax通信が成功したら発動
    //     .done( (data) => {
    //       alert("更新しました")
    //       location.reload();
    //     })
    //     // Ajax通信が失敗したら発動
    //     .fail( (jqXHR, textStatus, errorThrown) => {
    //       alert('通信に失敗しました。');
    //       console.log("jqXHR          : " + jqXHR.status); // HTTPステータスを表示
    //       console.log("textStatus     : " + textStatus);    // タイムアウト、パースエラーなどのエラー情報を表示
    //       console.log("errorThrown    : " + errorThrown.message); // 例外情報を表示
    //     })

    //   }
    // })




    // // 削除ボタンクリック時の動作
    // $("#mainContent").on('click', 'a.js-work-del', function (e) {
    //   e.preventDefault();

    //   const workDelUrl = "https://amyusement.fun/test/Edge_System/assets/ajax/work_del.php"
    //   let work_id = $(this).attr("data-id")
    //   $.ajax({
    //     url: workDelUrl,
    //     type: 'POST',
    //     data: {
    //       'work_id': work_id ,
    //     }
    //   })
    //   // Ajax通信が成功したら発動
    //   .done( (data) => {
    //     alert("削除しました")
    //     location.reload()
    //   })
    //   // Ajax通信が失敗したら発動
    //   .fail( (jqXHR, textStatus, errorThrown) => {
    //     alert('通信に失敗しました。');
    //     console.log("jqXHR          : " + jqXHR.status); // HTTPステータスを表示
    //     console.log("textStatus     : " + textStatus);    // タイムアウト、パースエラーなどのエラー情報を表示
    //     console.log("errorThrown    : " + errorThrown.message); // 例外情報を表示
    //   })
    // })

  }


}());
