// import * as $ from 'jquery';
import * as bootstrap from 'bootstrap'

export default (function () {
  // ------------------------------------------------------
  // @Popover
  // ------------------------------------------------------

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})

// このコメントアウトを外すと改善板が動作する
if ( $("#mainContent").find(".js-sales-date").length ) {

  $("#mainContent").on('click', ".js-sales-date", function (e) {


    e.preventDefault()
    let delUrl = "./assets/ajax/index_sales.php"
    let date = $(this).attr('data-sales-date');
    console.log(date)

    $.ajax({
      url: delUrl,
      type: 'POST',
      data: {
        'date': date ,
      },
      context : this,
    })
    // Ajax通信が成功したら発動
    .done( (data) => {
      console.log(data)
      $("#sales-data").empty()
      $("#sales-data").append(data)
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
      })
    })
    // Ajax通信が失敗したら発動
    .fail( (jqXHR, textStatus, errorThrown) => {
      alert('通信に失敗しました。');
      console.log("jqXHR          : " + jqXHR.status); // HTTPステータスを表示
      console.log("textStatus     : " + textStatus);    // タイムアウト、パースエラーなどのエラー情報を表示
      console.log("errorThrown    : " + errorThrown.message); // 例外情報を表示
    })
  })
}

  // ------------------------------------------------------
  // @Tooltips
  // ------------------------------------------------------

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
}());
