import * as $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

export default (function () {
  if ( $('#mainContent').find('.start-date') ) {
    $('.start-date').datepicker({
      assumeNearbyYear: true,
      format: 'yyyy-mm-dd',
    });
  }
  if ( $('#mainContent').find('.end-date') ) {
    $('.end-date').datepicker({
      assumeNearbyYear: true,
      format: 'yyyy-mm-dd'
    });
  }
}())
