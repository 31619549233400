import Chart from 'chart.js';

var myChart = ""
export function lineChart( attendance_label_list_data ,attendance_data_list1_data ,attendance_data_list2_data ,attendance_data_list3_data ,attendance_data_list4_data ) {
  // // ------------------------------------------------------
  // // @Line Charts
  // // ------------------------------------------------------

  const lineChartsBox = document.getElementById('line-charts');
  if(myChart.constructor === Chart){
    myChart.destroy();
  };

  if (lineChartsBox) {
    // ラベルとデータ
    let attendance_label_list = attendance_label_list_data
    let attendance_data_list1 = attendance_data_list1_data
    let attendance_data_list2 = attendance_data_list2_data
    let attendance_data_list3 = attendance_data_list3_data
    let attendance_data_list4 = attendance_data_list4_data

    // データのプロパティ
    let attendance_set4 = {
      label: '予定',
      data: attendance_data_list4,            // プロットするデータ
      borderWidth: 2,             // 折れ線の太さ
      borderColor: "#95ff80",     // 折れ線の色
      fill: false,
    };

    // データのプロパティ
    let attendance_set1 = {
      label: '出勤',
      data: attendance_data_list1,            // プロットするデータ
      borderWidth: 2,             // 折れ線の太さ
      borderColor: "#7fbfff",     // 折れ線の色
      fill: false,
    };

    // データのプロパティ
    let attendance_set2 = {
      label: '事前欠席',
      data: attendance_data_list2,            // プロットするデータ
      borderWidth: 2,             // 折れ線の太さ
      borderColor: "#ff8080",     // 折れ線の色
      fill: false,
    };

    // データのプロパティ
    let attendance_set3 = {
      label: '加算欠席',
      data: attendance_data_list3,            // プロットするデータ
      borderWidth: 2,             // 折れ線の太さ
      borderColor: "#ffff80",     // 折れ線の色

      fill: false,
    };




    const lineCtx = lineChartsBox.getContext('2d');
    lineChartsBox.height = 60;

    myChart = new Chart(lineCtx, {
      type: 'line',
      data: {
        labels: attendance_label_list,
        datasets: [attendance_set4, attendance_set1, attendance_set3 ,attendance_set2]
      },

      options: {
        legend: {
          display: true,
        },
        scales: {
          yAxes: [                           // Ｙ軸設定
              {
                  ticks: {                       // 目盛り
                      min: 0,                        // 最小値
                      stepSize: 2,                   // 軸間隔
                      fontSize: 14                   // フォントサイズ
                  }
              }
          ]
        },
      },

    });
  }
}
