import Chart from 'chart.js';
import { COLORS } from '../../constants/colors';

export default (function () {
  // // ------------------------------------------------------
  // // @Line Charts
  // // ------------------------------------------------------

  // const lineChartBox = document.getElementById('line-chart');

  // // ラベルとデータ
  // let attendance_label_list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19']
  // let attendance_data_list1 = [10, 12, 15, 10, 8, 10, 12, 15, 17, 14]
  // let attendance_data_list2 = [2, 2, 3, 4, 1, 2, 4, 2, 5, 6]
  // let attendance_data_list3 = [1, 2, 2, 3, 0, 2, 4, 1, 3, 0]
  // let attendance_data_list4 = [19, 15, 15, 13, 8, 15, 17, 18, 18, 19]


  // // データのプロパティ
  // let attendance_set1 = {
  //   label: '出席',
  //   data: attendance_data_list1,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   borderColor: "skyblue",     // 折れ線の色
  //   fill: false,
  // };

  // // データのプロパティ
  // let attendance_set2 = {
  //   label: '当欠',
  //   data: attendance_data_list2,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   borderColor: "yellow",     // 折れ線の色
  //   fill: false,
  // };

  // // データのプロパティ
  // let attendance_set3 = {
  //   label: '事前欠席',
  //   data: attendance_data_list3,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   borderColor: "red",     // 折れ線の色
  //   fill: false,
  // };

  // // データのプロパティ
  // let attendance_set4 = {
  //   label: '予定人数',
  //   data: attendance_data_list4,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   borderColor: "green",     // 折れ線の色
  //   fill: false,
  // };

  // if (lineChartBox) {
  //   const lineCtx = lineChartBox.getContext('2d');
  //   lineChartBox.height = 60;

  //   new Chart(lineCtx, {
  //     type: 'line',
  //     data: {
  //       labels: attendance_label_list,
  //       datasets: [attendance_set1, attendance_set2, attendance_set3, attendance_set4]
  //     },

  //     options: {
  //       legend: {
  //         display: true,
  //       },
  //     },

  //   });
  // }


  // // ------------------------------------------------------
  // // @Bar Charts
  // // ------------------------------------------------------

  // const barChartBox = document.getElementById('bar-chart');

  // // ラベルとデータ
  // let transfer_label_list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19']
  // let transfer_data_list1 = [1, 2, 2, 3, 0, 2, 4, 1, 3, 0]
  // let transfer_data_list2 = [2, 2, 3, 4, 1, 2, 4, 2, 5, 6]

  // // データのプロパティ
  // let transfer_set1 = {
  //   label: '送り',
  //   data: transfer_data_list1,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   backgroundColor: "green",     // 折れ線の色
  //   fill: false,
  // };

  // // データのプロパティ
  // let transfer_set2 = {
  //   label: '迎え',
  //   data: transfer_data_list2,            // プロットするデータ
  //   borderWidth: 2,             // 折れ線の太さ
  //   backgroundColor: "skyblue",     // 折れ線の色
  //   fill: false,
  // };


  // if (barChartBox) {
  //   const barCtx = barChartBox.getContext('2d');
  //   barChartBox.height = 60;

  //   new Chart(barCtx, {
  //     type: 'bar',
  //     data: {
  //       labels: transfer_label_list,
  //       datasets: [transfer_set1, transfer_set2],
  //     },

  //     options: {
  //       responsive: true,
  //       legend: {
  //         position: 'bottom',
  //       },
  //     },
  //   });
  // }

  // ------------------------------------------------------
  // @Area Charts
  // ------------------------------------------------------

  const areaChartBox = document.getElementById('area-chart');

  if (areaChartBox) {
    const areaCtx = areaChartBox.getContext('2d');

    new Chart(areaCtx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          backgroundColor : 'rgba(3, 169, 244, 0.5)',
          borderColor     : COLORS['light-blue-800'],
          data            : [10, 50, 20, 40, 60, 30, 70],
          label           : 'Dataset',
          fill            : 'start',
        }],
      },
    });
  }

  // ------------------------------------------------------
  // @Scatter Charts
  // ------------------------------------------------------

  const scatterChartBox = document.getElementById('scatter-chart');

  if (scatterChartBox) {
    const scatterCtx = scatterChartBox.getContext('2d');

    Chart.Scatter(scatterCtx, {
      data: {
        datasets: [{
          label           : 'My First dataset',
          borderColor     : COLORS['red-500'],
          backgroundColor : COLORS['red-500'],
          data: [
            { x: 10, y: 20 },
            { x: 30, y: 40 },
            { x: 50, y: 60 },
            { x: 70, y: 80 },
            { x: 90, y: 100 },
            { x: 110, y: 120 },
            { x: 130, y: 140 },
          ],
        }, {
          label           : 'My Second dataset',
          borderColor     : COLORS['green-500'],
          backgroundColor : COLORS['green-500'],
          data: [
            { x: 150, y: 160 },
            { x: 170, y: 180 },
            { x: 190, y: 200 },
            { x: 210, y: 220 },
            { x: 230, y: 240 },
            { x: 250, y: 260 },
            { x: 270, y: 280 },
          ],
        }],
      },
    });
  }
}())
